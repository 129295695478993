// ContextSwitcher.tsx
import React from 'react';
import { Form, Button } from 'react-bootstrap';
import '../../styles/ProcedureDetails.css';
import DatePicker from './DatePicker'; // Adjust the import path as needed
import calendar3 from '../../assets/images/calendar3.svg';
import { format } from 'date-fns';

interface ContextSwitcherProps {
    option: {
      id: string;
      parameterName: string;
      dataType: string;
    };
    inputValues: { [key: string]: string };
    setInputValues: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    selectedDates: { [key: string]: Date | undefined };
    handleDateChange: (optionId: string, date: Date) => void;
    setShowDatePicker: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    showDatePicker: { [key: string]: boolean };
  }
  

  const ContextSwitcher: React.FC<ContextSwitcherProps> = ({ 
    option, 
    inputValues, 
    setInputValues, 
    selectedDates, 
    handleDateChange, 
    setShowDatePicker, 
    showDatePicker 
  }) => {

    const handleDatePickerVisibility = () => {
      setShowDatePicker((prev) => ({
        ...prev,
        [option.id]: !prev[option.id],
      }));
    };

  return (
    <Form.Group className='form-group'>
      <Form.Label className='form-group label'>{option.parameterName}</Form.Label>
      {option.parameterName === '@Comment' ? (
        <textarea
          value={inputValues[option.id] || ''}
          placeholder='Please enter ticket number or why you are running this'
          maxLength={256}
          className='comment-textarea form-control'
          onChange={(e) => setInputValues({ ...inputValues, [option.id]: e.target.value })}
        />
      ) : option.parameterName === '@ClientNumber' ? (
        <Form.Control
          type="text"
          value={inputValues[option.id] || ''}
          placeholder='BeneSys Client Number'
          className='.form-group input'
          onChange={(e) => setInputValues({ ...inputValues, [option.id]: e.target.value })}
        />
      ) : option.parameterName === '@Username' ? (
        <Form.Control
          type="text"
          value={inputValues[option.id] || ''}
          placeholder='Your BeneSys Username'
          className='.form-group input'
          onChange={(e) => setInputValues({ ...inputValues, [option.id]: e.target.value })}
        />
      ) : option.dataType.toLowerCase() === 'date' || option.dataType.toLowerCase() === 'datetime' ? (
        <div className="input-group">
          <Form.Control
            type="datetime"
            value={selectedDates[option.id] ? format(selectedDates[option.id] as Date, 'MM-dd-yyyy') : ''}
            placeholder={option.dataType}
            className='.form-group input'
            readOnly
            onClick={() => handleDatePickerVisibility()}
          />
          <div>
            <Button onClick={handleDatePickerVisibility} className='cal-icon'>
              <img src={calendar3} alt="Calendar" />
            </Button>
          </div>
          {showDatePicker[option.id] && (
            <DatePicker
              onSelectDate={(date) => {
                handleDateChange(option.id, date);
                handleDatePickerVisibility();
              }}
            />
          )}
        </div>
      ) : option.dataType.toLowerCase() === 'bit' || option.dataType.toLowerCase() === 'boolean' ? (
        <div>
          <Form.Check
            type="radio"
            label="True"
            name={option.parameterName}
            id={`true-${option.id}`}
            onChange={() => setInputValues({ ...inputValues, [option.id]: '1' })}
            checked={inputValues[option.id] === '1'}
            className='radio-button-true'
          />
          <Form.Check
            type="radio"
            label="False"
            name={option.parameterName}
            id={`false-${option.id}`}
            onChange={() => setInputValues({ ...inputValues, [option.id]: '0' })}
            checked={inputValues[option.id] === '0'}
            className='radio-button-false'
          />
        </div>
      ) : (
        <Form.Control
          type="text"
          value={inputValues[option.id] || ''}
          placeholder={option.dataType}
          className='.form-group input'
          onChange={(e) => setInputValues({ ...inputValues, [option.id]: e.target.value })}
        />
      )}
    </Form.Group>
  );
};

export default ContextSwitcher;