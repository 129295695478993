import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { msalInstance } from '../index';
import { useMsal, useAccount } from '@azure/msal-react';
import { loginRequest } from '../auth/AuthConfig';
import VerificationPopup from './VerificationPopup';
import verifyInfo from './utilities/verifyInfo';
import ContextSwitcher from './utilities/ContextSwitcher';
import { format } from 'date-fns';


interface Props {
  options: any[]; // This should contain data like RunHistoryCalcParameters
  handleOptionChange: (optionId: string, value: string) => void;
  verifyInfo: () => void;
  selectedProcedureName: any | null;
}

interface SelectedDates {
  [key: string]: Date | undefined;
}

const OptionForm: React.FC<Props> = ({ options, selectedProcedureName }) => {
  const [verificationResults, setVerificationResults] = useState<string[]>([]);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [showVerificationPopup, setShowVerificationPopup] = useState<boolean>(false);
  const [, setConfirmedVerification] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<Record<string, Date | undefined>>({});
  const [showDatePicker, setShowDatePicker] = useState<{ [key: string]: boolean }>({});
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const navigate = useNavigate();

  // split account.username at the @ symbol and take the first part of the string
  const username = account?.username.split('@')[0];

    useEffect(() => {
      const today = new Date();
      const newSelectedDates: SelectedDates = {};
      
      options.forEach((option) => {
        if (option.dataType.toLowerCase() === 'datetime' || option.dataType.toLowerCase() === 'date') {
          newSelectedDates[option.id] = today;
        }
      });
      
      // Now you can use `newSelectedDates` in your state or wherever necessary
      setSelectedDates(newSelectedDates);
    }, [options]);

    const handleDateChange = (optionId: string, date: Date) => {
      setSelectedDates({
        ...selectedDates,
        [optionId]: date,
      });
    };


  const toggleVerificationPopup = () => {
    setShowVerificationPopup(!showVerificationPopup);
  };

  const returnToDashboard = () => {
    navigate('/dashboard');
  };

  const handleVerifyInfo = () => {
    verifyInfo({
      options: options,
      inputValues: inputValues,
      setVerificationResults: setVerificationResults,
      toggleVerificationPopup: toggleVerificationPopup,
      selectedDates: selectedDates,
    });
  };

  const handleConfirm = () => {
    // Handle the "Confirm" button click
    setConfirmedVerification(true);
    submitData();
  };

  const submitData = () => {
    // Ensure selectedProcedureName is not null before submitting data
    if (!selectedProcedureName) {
      console.error('No procedure selected.');
      return; // Exit the function if no procedure is selected
    }
    // put the userinput into parameters dynamically and put the names of the parameters into the formdata
    const parameters: { [key: string]: string }[] = options.map(option => {
      let value;
      if (option.dataType.toLowerCase() === 'datetime' && selectedDates[option.id]) {
        value = format(selectedDates[option.id] as Date, 'MM-dd-yyyy');
      } else {
        value = inputValues[option.id] || '';
      }
      return { [option.parameterName]: value };
    });

    // Adding username to the parameters
    parameters.push({ '@Username': username ?? '' });

    const request = loginRequest; 
    const a = "Authorization";

    msalInstance.acquireTokenSilent(request).then(tokenResponse => {
      const bearer = "Bearer " + tokenResponse.accessToken;

    const headers = new Headers({'content-type': 'application/json'});
    headers.append(a, bearer);
    const options = {
            method: "POST",
            headers: headers,
            'Content-Type': 'application/json',

            body: JSON.stringify({ 
              procedureName: selectedProcedureName,
              parameters, // parameters is now an array of objects
            })
    };

    const endURL = '/api/Procedure/QueueProcedureAsync';
    const baseURL = process.env.REACT_APP_API_URL;

    const pushData = async () => {
      await fetch(baseURL+endURL, options)
        .then((data) => {
          console.log('Confirmation response:', data);
          alert('Data submitted successfully!');
          navigate('/dashboard');
        })
        .catch((error) => console.error('Failed to confirm data:', error));
    };
    pushData();
  });
  };

  
  return (
    <Container>
      <Row>
        <Col xs={12} md={6}>
          <Form>
          {options.map((option) => (
              <ContextSwitcher
                key={option.id}
                option={option}
                inputValues={inputValues}
                setInputValues={setInputValues}
                selectedDates={selectedDates}
                handleDateChange={handleDateChange}
                setShowDatePicker={setShowDatePicker}
                showDatePicker={showDatePicker}
              />
            ))}
            {/*Add Functionality so that */}
            <div>
            <Button className="verInfobutton" variant="primary" onClick={handleVerifyInfo}>
              Verify Information
            </Button>
            <Button className="retDashboardButton" variant="primary" onClick={returnToDashboard}>
              Return To Dashboard
            </Button>
            </div>
          </Form>
        </Col>
      </Row>

      {/* Show the VerificationPopup */}
      <VerificationPopup
        show={showVerificationPopup}
        onHide={toggleVerificationPopup}
        verificationResults={verificationResults}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default OptionForm;